import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'
import Personality from '../views/Personality.vue'
import Share from '../views/Share.vue'
import { optionsValid } from '@/models/personality'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/personality',
    name: 'personality',
    component: Personality
  },
  {
    path: '/share',
    name: 'share',
    component: Share
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior () {
    // always scroll to top
    return { top: 0 }
  }
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'home' && !optionsValid.value) next({ name: 'home' })
  else next()
})

export default router
