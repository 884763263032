export interface Ice {
    name: string;
    color: string;
}

export const iceList: Array<Ice> = [
  { name: 'Apple Berry Blue', color: 'Blue' },
  { name: 'Banana', color: 'Yellow' },
  { name: 'Banana Split Cream', color: 'Yellow' },
  { name: 'Birthday Cake', color: 'Yellow' },
  { name: 'Black Cherry Cheesecake', color: 'Pink' },
  { name: 'Blue Raspberry', color: 'Blue' },
  { name: 'Blueberry', color: 'Blue' },
  { name: 'Brookie Dough', color: 'Brown' },
  { name: 'Cantaloupe', color: 'Orange' },
  { name: 'Cheesecake', color: 'Yellow' },
  { name: 'Cherry', color: 'Red' },
  { name: 'Cherry Lemonade', color: 'Pink' },
  { name: 'Cherry Limeade', color: 'Pink' },
  { name: 'Chocolate', color: 'Brown' },
  { name: 'Chocolate Chip Cheesecake', color: 'Yellow' },
  { name: 'Chocolate Chocolate Chip', color: 'Brown' },
  { name: 'Chocolate Covered Banana', color: 'Yellow' },
  { name: 'Chocolate Covered Strawberry', color: 'Pink' },
  { name: 'Chocolate Peanut Butter', color: 'Brown' },
  { name: 'Coconut Cream', color: 'White' },
  { name: 'Cookie Dough', color: 'Brown' },
  { name: 'Cookies N Cream', color: 'White' },
  { name: 'Cotton Candy', color: 'Blue' },
  { name: 'Dr Pepper', color: 'Brown' },
  { name: 'Florida Orange', color: 'Orange' },
  { name: 'Fudge Brownie', color: 'Brown' },
  { name: 'Georgia Peach', color: 'Orange' },
  { name: 'Green Apple', color: 'Green' },
  { name: 'Guava Passionfruit', color: 'Orange' },
  { name: 'Island Fusion', color: 'Orange' },
  { name: 'Juicy Pear', color: 'Green' },
  { name: 'Key Lime', color: 'Green' },
  { name: 'Lemon', color: 'Yellow' },
  { name: 'Mango', color: 'Orange' },
  { name: 'Mango Orange', color: 'Orange' },
  { name: 'Mango Peach', color: 'Orange' },
  { name: 'Mango Pineapple', color: 'Orange' },
  { name: 'Mango Strawberry', color: 'Orange' },
  { name: 'Margarita', color: 'Green' },
  { name: 'Marshmallow Peanut Butter', color: 'Brown' },
  { name: 'Mint Chocolate Chip', color: 'Green' },
  { name: 'Mint Oreo', color: 'Green' },
  { name: 'Mojito', color: 'Green' },
  { name: 'Ocean Splash', color: 'Blue' },
  { name: 'Orange Cream', color: 'Orange' },
  { name: 'Peanut Butter & Jelly', color: 'Red' },
  { name: 'Pina Colada', color: 'Yellow' },
  { name: 'Pineapple', color: 'Yellow' },
  { name: 'Pumpkin Cheesecake', color: 'Orange' },
  { name: 'Pumpkin Pie', color: 'Orange' },
  { name: 'Root Beer', color: 'Brown' },
  { name: 'Root Beer Float', color: 'Brown' },
  { name: 'S\'mores', color: 'White' },
  { name: 'Shamrock', color: 'Green' },
  { name: 'Sour Patch Kids Red', color: 'Red' },
  { name: 'Strawberries N Cream', color: 'Pink' },
  { name: 'Strawberry', color: 'Red' },
  { name: 'Strawberry Banana', color: 'Pink' },
  { name: 'Strawberry Cheesecake', color: 'Pink' },
  { name: 'Strawberry Colada', color: 'Pink' },
  { name: 'Strawberry Lemonade', color: 'Pink' },
  { name: 'Strawberry Margarita', color: 'Pink' },
  { name: 'Strawberry Watermelon', color: 'Pink' },
  { name: 'Sugar-Free Cherry', color: 'Red' },
  { name: 'Sugar-Free Dragon Fruit', color: 'Pink' },
  { name: 'Sugar-Free Mango Peach', color: 'Orange' },
  { name: 'Sugar-Free Tangerine', color: 'Orange' },
  { name: 'Swedish Fish®', color: 'Red' },
  { name: 'Tropical Daiquiri', color: 'Red' },
  { name: 'Unicorn', color: 'Purple' },
  { name: 'Vanilla', color: 'White' },
  { name: 'Vanilla Cream', color: 'White' },
  { name: 'Watermelon', color: 'Pink' },
  { name: 'Watermelon Chip', color: 'Pink' },
  { name: 'Wild Berry', color: 'Purple' },
  { name: 'Wild Black Cherry', color: 'Purple' },
  { name: 'Zombie\'s Blood', color: 'Red' }
]
