<template>
  <div class="wrapper">
    <header class="header">
      <div class="container">
        <a href="https://www.ritasice.com/locations/" class="location"><span class="icon-map-marker-light"></span></a>
        <div class="logo"><a href="/"><img src="./assets/logo.svg" alt="Rita's"></a></div>
      </div>
    </header>
    <main class="main">
      <div class="container">
        <router-view/>
      </div>
    </main>
  </div>
</template>

<style lang="scss">
@import './assets/styles/main.css';

.header {
  padding: 17px 0;
}
.header .container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.header a {
  color: #fff;
  font-size: 20px;
  line-height: 22px;
}
.header a:hover {
  text-decoration: none;
  color: #c50bae;
}

.logo {
  width: 62px;
  margin: 0 auto;
}
.logo img {
  display: block;
  width: 100%;
  height: auto;
}
a {
  cursor: pointer;
}
</style>
