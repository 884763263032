import { computed, reactive } from 'vue'
import { Custard } from './custard'
import { Ice } from './ice'

export interface Personality {
  name: string
  description: string
  shareMsg: string
  colors: Array<string>
}

export const personalityList: Array<Personality> = [
  {
    name: 'Listener',
    description:
      'You are even-tempered, sincere, compassionate, and imaginative. You are sensitive to the needs of others, and are very caring with your close friends. You’re the first person to offer to split your Rita’s treat after a friend dropped theirs. One of your best qualities is your ability to listen and give advice without judgment. You are not generally impulsive and prefer to think things through before taking action. You enjoy having time to yourself to relax and decompress.',
    shareMsg:
      'You’re the first person to offer to split your Rita’s treat after a friend dropped theirs.',
    colors: ['Blue', 'White']
  },
  {
    name: 'Creator',
    description:
      'You are thoughtful, inquisitive, optimistic, and creative. You tend to be a very communicative person and enjoy making new connections. Getting to know people comes very naturally to you. You’re the type of person that makes a new friend everywhere you go, even when you’re in line at Rita’s. Your enthusiastic personality makes you a great creative thinker, performer, and volunteer. You’re a team player, and alway ready to lend a helping hand.',
    shareMsg:
      'You’re the type of person that makes a new friend everywhere you go, even when you’re in line at Rita’s.',
    colors: ['Yellow', 'Orange']
  },
  {
    name: 'Risk Taker',
    description:
      'You are passionate, energetic, determined, and ambitious. You’re a go-getter and never afraid to take risks to get what you want. Your energetic personality makes it exciting to be around you. You’re passionate about life and your enthusiasm is contagious to anyone who meets you. You’re always the first person to suggest a spontaneous trip to Rita’s. You’re achievement-oriented and not afraid to pursue your dreams and goals.',
    shareMsg:
      'You’re always the first person to suggest a spontaneous trip to Rita’s.',
    colors: ['Red', 'Pink', 'Purple']
  },
  {
    name: 'Optimist',
    description:
      'You are kind, loyal, generous, and compassionate. People like to have you around in a crisis because of your calm nature, and your ability to easily take control of a situation. You are a very nurturing person and care deeply about others and their well-being. You are always striving for peace and harmony in every relationship. You prefer to look at your Rita’s cup half full rather than half empty. Those who know you find your positivity admirable.',
    shareMsg:
      'You prefer to look at your Rita’s cup half full rather than half empty.',
    colors: ['Green', 'Brown']
  }
]

export interface Answers {
  firstCustard: Custard | null
  firstIce: Ice | null
  secondCustard: Custard | null
  secondIce: Ice | null
  thirdCustard: Custard | null
}

export const answers: Answers = reactive({
  firstCustard: null,
  firstIce: null,
  secondCustard: null,
  secondIce: null,
  thirdCustard: null
})

export const optionsValid = computed(() =>
  answers.firstCustard &&
  answers.firstIce &&
  answers.secondCustard &&
  answers.secondIce &&
  answers.thirdCustard
)

export const selectedPersonality = computed(() =>
  personalityList.find((x) =>
    x.colors.includes(<string>answers.firstIce?.color)
  )
)

export const gelatiPiecePaths = computed(() => {
  return {
    pathOne: `/img/gelati-pieces/1${answers.firstCustard?.color}.png`,
    pathTwo: `/img/gelati-pieces/2${answers.firstIce?.color}.png`,
    pathThree: `/img/gelati-pieces/3${answers.secondCustard?.color}.png`,
    pathFour: `/img/gelati-pieces/4${answers.secondIce?.color}.png`,
    pathFive: `/img/gelati-pieces/5${answers.thirdCustard?.color}.png`
  }
})
