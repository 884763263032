export interface Custard {
    name: string;
    color: string;
}

export const custardList: Array<Custard> = [
  { name: 'Banana', color: 'Yellow' },
  { name: 'Black Raspberry', color: 'Purple' },
  { name: 'Butter Pecan', color: 'Brown' },
  { name: 'Cake Batter', color: 'White' },
  { name: 'Caramel Macchiato', color: 'Brown' },
  { name: 'Cheesecake', color: 'White' },
  { name: 'Chocolate', color: 'Brown' },
  { name: 'Chocolate Salted Caramel', color: 'Brown' },
  { name: 'Coffee', color: 'Brown' },
  { name: 'Cotton Candy', color: 'Blue' },
  { name: 'Mint', color: 'Green' },
  { name: 'Orange Cream', color: 'Orange' },
  { name: 'Pistachio', color: 'Green' },
  { name: 'Pumpkin', color: 'Orange' },
  { name: 'Salted Caramel', color: 'Brown' },
  { name: 'Strawberry Banana', color: 'Pink' },
  { name: 'Strawberry', color: 'Pink' },
  { name: 'Twist', color: 'Twist' },
  { name: 'Vanilla', color: 'White' }
]
